import React, { useContext } from 'react';

import * as classes from './riverSection.module.scss';

import { DaylightContext } from '../../contexts/daylightContext';

import Water from '../svg/water/Water';
import Boat from '../svg/boat/Boat';
import Swan from '../svg/swan/Swan';

// sail will be true when not on the home page. Only show the water & the boat on other pages.
const RiverSection = ({ sail = false }) => {
	const { daylight } = useContext(DaylightContext);
	return (
		<div
			className={daylight ? classes.riverSection : classes.riverSectionDark}
			aria-hidden="true"
		>
			{!sail && <div className={classes.seaWall}></div>}

			<div
				className={classes.water}
				style={{
					background: ` ${
						sail && daylight
							? 'var(--blue)'
							: sail && !daylight
							? 'var(--nightSky)'
							: 'var(--houseBlue)'
					}`,
				}}
			>
				<Water sail={sail} />
				<Boat classes={classes} sail={sail} />

				{daylight && !sail && <Swan classes={classes} />}
			</div>
		</div>
	);
};

export default RiverSection;
