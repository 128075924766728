import React from 'react';

export default function Swan({ classes }) {
	return (
		<svg
			className={classes.swan}
			width="358.792"
			height="181.622"
			viewBox="0 0 94.93 48.054"
		>
			<defs />
			<path
				fill="#f2f2f2"
				d="M78.163.681c6.493-3.548 18.633 7.717 13.608 11.942-.271 2.012 2.485 7.195 1.693 7.205-3.911-3.554-6.687-6.42-11.44-8.868 1.532 5.535 10.562 21.195 12.888 32.095.15 2-.573 5.464-3.239 4.814l-79.389.185L0 41.44c8.155-.94 18.541-2.746 25.23-1.512L6.52 30.006c2.866-5.487 8.667-8.459 14.384-10.125-.103-2.799-7.357-1.68-7.357-1.68s11.694-8.286 20.1-4.857l-8.04-4.505c13.319-2.55 20.438-1.258 27.212 8.077-1.583-3.783-4.105-7.311-4.74-11.421 17.061.85 21.057 32.251 32.91 34.268C83.866 28.99 67.506 7.428 78.162.68z"
			/>
			<path d="M87.266 14.375s3.061-3.653.59-7.984c-2.268-3.973 3.298-.741 3.298-.741s1.676 2.343 1.837 3.863c.22 2.082-1.22 3.11-1.22 3.11-1.483.527-3.023 1.223-4.505 1.752z" />
			<path
				fill="#fd9d1f"
				d="M91.771 12.623c.302 2.084 2.427 7.626 1.693 7.205-.081-.022-6.198-5.453-6.198-5.453s4.45-1.97 4.505-1.752z"
			/>
		</svg>
	);
}
