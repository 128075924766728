import React from 'react';

export default function Boat({ classes, sail }) {
	return (
		<svg
			className={sail ? classes.boatSail : classes.boat}
			width="452.103"
			height="112.003"
			viewBox="0 0 119.619 29.634"
		>
			<defs />
			<g transform="translate(100.38707,-135.48273)">
				<path
					fill="#500"
					d="M-66.215 151.49l.469-16.007 40.69.237 6.682 13.097s-42.763 5.078-47.841 2.672z"
				/>
				<path
					fill="red"
					d="M-100.387 151.283l1.274 11.252 111.72 2.582 6.57-18.294s.936-1.871-4.944-1.336c-5.88.534-45.169 4.41-60.804 5.078-15.635.668-50.303 1.838-53.816.718z"
				/>
				<rect
					width="6.48"
					height="7.185"
					x="-63.156"
					y="140.164"
					fill="#fff"
					ry=".092"
				/>
				<rect
					width="6.48"
					height="7.185"
					x="-51.823"
					y="140.164"
					fill="#fff"
					ry=".092"
				/>
				<rect
					width="6.48"
					height="7.185"
					x="-40.509"
					y="140.164"
					fill="#fff"
					ry=".092"
				/>
				<path
					fill="none"
					stroke="#000"
					strokeWidth=".265"
					d="M-99.638 156.5s109.424.189 115.471-.378"
				/>
				<path
					fill="#a00"
					d="M-30.654 164.049l-55.266-1.302c-7.165-.168-13.046-.326-13.069-.35-.053-.057-.646-4.947-.646-5.328 0-.261 3.77-.292 36.711-.302 20.191-.006 46.109-.081 57.595-.167 11.485-.086 20.896-.145 20.912-.13.016.013-.664 1.96-1.511 4.324-1.465 4.088-1.564 4.298-2.014 4.275-.26-.013-19.48-.472-42.712-1.02z"
				/>
			</g>
		</svg>
	);
}
