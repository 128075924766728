// extracted by mini-css-extract-plugin
export var boat = "riverSection-module--boat--f+iEY";
export var boatSail = "riverSection-module--boatSail--jgZgP";
export var riverSection = "riverSection-module--riverSection--4tPPL";
export var riverSectionDark = "riverSection-module--riverSectionDark--3ZCwE";
export var sail = "riverSection-module--sail--km5fU";
export var seaWall = "riverSection-module--sea-wall--6Q58z";
export var swan = "riverSection-module--swan--oBNqc";
export var swell = "riverSection-module--swell--zUo3R";
export var swell2 = "riverSection-module--swell2--5Kgte";
export var swim = "riverSection-module--swim--ZHgn+";
export var water = "riverSection-module--water--Nrx4H";
export var wave4 = "riverSection-module--wave4--ikzGU";
export var waves = "riverSection-module--waves--mzK7Q";