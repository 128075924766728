import React, { useContext } from 'react';
import * as classes from './water.module.scss';
import { DaylightContext } from '../../../contexts/daylightContext';

// Sail is badly named. It means the animmation is showing on the bottom of a page (not home page with complete animation). sail for water will mean add .blendTop for a wave shaped top. This goes on wave 4 (technically 4 should be at the top)
export default function Water({ sail }) {
	const { daylight } = useContext(DaylightContext);
	return (
		<svg
			className={classes.water}
			preserveAspectRatio="none"
			// width="1033.2822"
			height="382.48111"
			viewBox="0 0 273.38924 101.19813"
			version="1.1"
			id="svg923"
		>
			<path
				d="M117.153 12.264l-45.834 7.4 45.834-7.4zm129.794-5.953c-19.054-.267-34.679 16.67-54.196 12.497-14.66-2.615-27.888-15.653-43.496-10.076-13.027 4.27-26.003 14.47-40.317 8.9C92.545 13.249 75.02 3.78 58.085 11.658c-13.03 6.327-27.863 11.53-42.38 7.177-5.297.659-14.91-9.89-15.75-5.33v88.11h273.873V17.182c-7.075-6.984-16.968-10.872-26.88-10.871z"
				fill="#404e5e"
			/>
			<g id="layer1" transform="translate(61.477382,-80.936649)">
				<path
					className={`${classes.wave} ${classes.wave1}`}
					id="wave1"
					d="M-56.914 145.655a39.113 39.113 0 0017.361 11.296 39.113 39.113 0 0020.698.78c6.532-1.543 12.53-4.736 18.664-7.458 6.135-2.722 12.696-5.028 19.394-4.618 6.698.41 12.899 3.486 18.946 6.394 6.047 2.908 12.407 5.764 19.117 5.682 6.713-.082 12.998-3.091 19.03-6.038 6.032-2.947 12.318-5.957 19.03-6.038 6.71-.082 13.069 2.774 19.116 5.682 6.047 2.908 12.247 5.984 18.944 6.394 6.7.41 13.26-1.896 19.395-4.618 6.136-2.722 12.134-5.915 18.666-7.458a39.114 39.114 0 0120.698.78 39.114 39.114 0 0117.362 11.296"
				/>
				<path
					className={`${classes.wave} ${classes.wave2}`}
					id="wave2"
					d="M-56.914 125.498a39.113 39.113 0 0017.361 11.296 39.113 39.113 0 0020.698.78c6.532-1.543 12.53-4.737 18.664-7.458 6.135-2.722 12.696-5.029 19.394-4.618 6.698.41 12.899 3.486 18.946 6.394 6.047 2.908 12.407 5.763 19.117 5.682 6.713-.082 12.998-3.092 19.03-6.038 6.032-2.947 12.318-5.957 19.03-6.038 6.71-.082 13.069 2.774 19.116 5.681 6.047 2.908 12.247 5.984 18.944 6.395 6.7.41 13.26-1.896 19.395-4.618 6.136-2.722 12.134-5.916 18.666-7.458a39.114 39.114 0 0120.698.78 39.114 39.114 0 0117.362 11.296"
				/>
				<path
					className={`${classes.wave} ${classes.wave3}`}
					id="wave3"
					d="M-56.914 105.34a39.113 39.113 0 0017.361 11.297 39.113 39.113 0 0020.698.78c6.532-1.543 12.53-4.737 18.664-7.458 6.135-2.722 12.696-5.029 19.394-4.619 6.698.41 12.899 3.486 18.946 6.394 6.047 2.909 12.407 5.764 19.117 5.682 6.713-.081 12.998-3.091 19.03-6.038 6.032-2.946 12.318-5.956 19.03-6.038 6.71-.081 13.069 2.774 19.116 5.682 6.047 2.908 12.247 5.984 18.944 6.394 6.7.411 13.26-1.896 19.395-4.618 6.136-2.721 12.134-5.915 18.666-7.458a39.114 39.114 0 0120.698.78 39.114 39.114 0 0117.362 11.296"
				/>
				<path
					className={`${classes.wave} ${classes.wave4} ${
						sail && daylight
							? classes.blendTopLight
							: sail && !daylight
							? classes.blendTopDark
							: null
					}`}
					id="wave4"
					d="M-56.914 85.183a39.113 39.113 0 0017.361 11.296 39.113 39.113 0 0020.698.78c6.532-1.543 12.53-4.736 18.664-7.458 6.135-2.721 12.696-5.028 19.394-4.618 6.698.41 12.899 3.486 18.946 6.394 6.047 2.908 12.407 5.764 19.117 5.682 6.713-.082 12.998-3.091 19.03-6.038 6.032-2.947 12.318-5.956 19.03-6.038 6.71-.082 13.069 2.774 19.116 5.682 6.047 2.908 12.247 5.984 18.944 6.394 6.7.41 13.26-1.896 19.395-4.618 6.136-2.722 12.134-5.915 18.666-7.458a39.114 39.114 0 0120.698.78 39.114 39.114 0 0117.362 11.296"
				/>
				<path
					className={`${classes.wave} ${classes.wave5}`}
					id="wave5"
					d="M-59.072 165.812a39.113 39.113 0 0017.361 11.297 39.113 39.113 0 0020.699.78c6.531-1.543 12.529-4.737 18.664-7.459 6.134-2.721 12.695-5.028 19.393-4.618 6.698.41 12.899 3.486 18.946 6.394 6.048 2.908 12.408 5.764 19.117 5.682 6.713-.081 12.998-3.091 19.03-6.038 6.032-2.947 12.318-5.956 19.03-6.038 6.71-.081 13.07 2.774 19.116 5.682 6.047 2.908 12.247 5.984 18.944 6.394 6.7.411 13.26-1.896 19.396-4.618 6.135-2.721 12.133-5.915 18.665-7.458a39.114 39.114 0 0120.698.78 39.114 39.114 0 0117.362 11.296"
				/>
			</g>
		</svg>
	);
}
