// extracted by mini-css-extract-plugin
export var blendTopDark = "water-module--blendTopDark--FW1GD";
export var blendTopLight = "water-module--blendTopLight--sa80I";
export var swell = "water-module--swell--4v6gc";
export var swell2 = "water-module--swell2--c-pCv";
export var water = "water-module--water--xXYeY";
export var wave = "water-module--wave--0dqpj";
export var wave1 = "water-module--wave1--N5SPa";
export var wave2 = "water-module--wave2--2Mm6T";
export var wave3 = "water-module--wave3--x5Ee0";
export var wave4 = "water-module--wave4--dexAz";
export var wave5 = "water-module--wave5--BIGvs";